<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance the chemical reaction shown below. Be sure to include values of 1 for any species
        that only have 1 equivalent involved in the reaction (i.e. empty inputs DO NOT correspond to
        the value "1"). Use integers for ALL coefficients.
      </p>

      <p class="mb-2">
        <v-text-field
          v-model="inputs.ab"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol1" />

        <v-text-field
          v-model="inputs.cd"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol2" />

        <v-text-field
          v-model="inputs.ad"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol3" />

        <v-text-field
          v-model="inputs.cb"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol4" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question356',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        ab: null,
        cd: null,
        ad: null,
        cb: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1: // 3:1:3:2
          return {
            mol1Var: 'AB2(g) +',
            mol2Var: 'C2D3(g) -> ',
            mol3Var: 'AD(g) +',
            mol4Var: 'CB3(g)',
          };
        case 2: // 3:2:3:1
          return {
            mol1Var: 'AB(g) +',
            mol2Var: 'CD3(g) -> ',
            mol3Var: 'AD2(g) +',
            mol4Var: 'C2B3(g)',
          };
        case 3: // 3:1:3:1
          return {
            mol1Var: 'AB(g) +',
            mol2Var: 'C2D3(g) -> ',
            mol3Var: 'A2D(g) +',
            mol4Var: 'CB3(g)',
          };
        case 4: // 6:1:3:2
          return {
            mol1Var: 'AB(g) +',
            mol2Var: 'C2D3(g) -> ',
            mol3Var: 'A2D(g) +',
            mol4Var: 'CB3(g)',
          };
        case 5: // 3:2:6:1
          return {
            mol1Var: 'A2B(g) +',
            mol2Var: 'CD3(g) -> ',
            mol3Var: 'AD(g) +',
            mol4Var: 'C2B3(g)',
          };
        default:
          return {
            mol1Var: 'ERROR +',
            mol2Var: 'ERROR -> ',
            mol3Var: 'ERROR +',
            mol4Var: 'ERROR',
          };
      }
    },
    mol1() {
      return this.versionData.mol1Var;
    },
    mol2() {
      return this.versionData.mol2Var;
    },
    mol3() {
      return this.versionData.mol3Var;
    },
    mol4() {
      return this.versionData.mol4Var;
    },
  },
};
</script>
